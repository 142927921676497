import { Injectable } from '@angular/core';
import { UnansweredQuestionModel } from '../shared/unanswered-popup-simple/unanswered-question.model';
import { Co2QuestionnaireAnswersModel } from './model/co2-questionnaire-answers.model';

@Injectable({ providedIn: 'root' })
export class Co2CalculatorValidationService {
  validateUnansweredQuestions(answers: Co2QuestionnaireAnswersModel): Set<UnansweredQuestionModel> {
    const result: Set<UnansweredQuestionModel> = new Set();

    this.validateGeneral(answers, result);
    this.validateEnergy(answers, result);
    this.validateMobility(answers, result);
    this.validateBoard(answers, result);
    this.validateMaterial(answers, result);

    return result;
  }

  validateCategory(answers: Co2QuestionnaireAnswersModel, category: string): boolean {
    const result: Set<UnansweredQuestionModel> = new Set();

    // eslint-disable-next-line default-case
    switch (category) {
      case 'general':
        this.validateGeneral(answers, result);
        break;
      case 'energy':
        this.validateEnergy(answers, result);
        break;
      case 'mobility':
        this.validateMobility(answers, result);
        break;
      case 'board':
        this.validateBoard(answers, result);
        break;
      case 'material':
        this.validateMaterial(answers, result);
        break;
    }

    return result.size === 0;
  }

  private validateGeneral(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateGeneralField(answers.name, 1, result);
    this.validateGeneralField(answers.country, 2, result);
    this.validateGeneralField(answers.numberOfEmployees, 3, result);
    this.validateGeneralField(answers.annualTurnover, 4, result);
    this.validateGeneralField(answers.heatedArea, 5, result);
  }

  private validateGeneralField(value: unknown, id: number, result: Set<UnansweredQuestionModel>): void {
    const categoryName = 'co2calculator.questionnaire.categories.general';

    if (!this.isValid(value, true)) {
      result.add(new UnansweredQuestionModel(id.toString(), categoryName, 1));
    }
  }

  private validateEnergy(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateEnergyField(answers.powerConsumptionKwh, 6, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.greenElectricity, 7, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.heatPump, 8, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.oilLiters, 9, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.naturalGasM3, 10, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.biogasM3, 11, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.woodKwh, 12, !answers.estimateEnergy, result);
    this.validateEnergyField(answers.districtHeatKwh, 13, !answers.estimateEnergy, result);
  }

  private validateMobility(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateMobilityField(answers.yearlyPublicTransportationKm, 14, !answers.estimateCommutingMobility, result);
    this.validateMobilityField(answers.yearlyCarKm, 15, !answers.estimateCommutingMobility, result);

    this.validateMobilityField(
      answers.businessYearlyCarKm,
      16,
      !answers.estimateBusinessTripMobility && !answers.advancedBusinessTripInputs,
      result,
    );

    this.validateMobilityField(
      answers.carFuelGasolineLiters,
      17,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelDieselLiters,
      18,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelNaturalGasKg,
      19,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelBiogasKg,
      20,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(
      answers.carFuelElectricKwh,
      21,
      !answers.estimateBusinessTripMobility && answers.advancedBusinessTripInputs,
      result,
    );
    this.validateMobilityField(answers.yearlyPublicTransportKm, 22, !answers.estimateBusinessTripMobility, result);
    this.validateMobilityField(answers.hotelNightAmount, 23, !answers.estimateBusinessTripMobility, result);

    this.validateMobilityField(answers.shortFlightsAmount, 24, true, result);
    this.validateMobilityField(answers.mediumFlightsAmount, 25, true, result);
    this.validateMobilityField(answers.longFlightsAmount, 26, true, result);
    // this.validateMobilityField(answers.businessOrFirstFlightsPerCent, 14, true, result);
    this.validatePercentageField(
      answers.businessOrFirstFlightsPerCent,
      27,
      'co2calculator.questionnaire.categories.mobility',
      3,
      result,
    );
  }

  private validateMobilityField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.mobility';

    this.validateField(categoryName, 3, value, id, required, result);
  }

  private validateEnergyField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.energy';

    this.validateField(categoryName, 2, value, id, required, result);
  }

  private validateBoard(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateBoardField(answers.warmMealMeatAmount, 28, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.warmMealVegaAmount, 29, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.snacks, 30, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.sodaLiters, 31, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.coffeePortions, 32, !answers.estimateFoodAndBeverages, result);
    this.validateBoardField(answers.teaPortions, 33, !answers.estimateFoodAndBeverages, result);
  }

  private validateBoardField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.board';

    this.validateField(categoryName, 4, value, id, required, result);
  }

  private validateMaterial(answers: Co2QuestionnaireAnswersModel, result: Set<UnansweredQuestionModel>): void {
    this.validateMaterialField(answers.paperKg, 34, !answers.estimateSupplies, result);
    this.validateMaterialField(answers.paperRecyclingPaperPerCent, 35, !answers.estimateSupplies, result);
    this.validateMaterialField(answers.printedMattersKg, 36, !answers.estimateSupplies, result);
    this.validateMaterialField(answers.electricDevicesAmount, 37, !answers.estimateSupplies, result);
    this.validateMaterialField(
      answers.tonersAmount,
      38,
      !answers.estimateSupplies && answers.advancedSuppliesInputs,
      result,
    );
    this.validateMaterialField(
      answers.waterM3,
      39,
      !answers.estimateSupplies && answers.advancedSuppliesInputs,
      result,
    );
    this.validateMaterialField(answers.garbageKg, 40, !answers.estimateSupplies, result);
    this.validateMaterialField(
      answers.recyclingKg,
      41,
      !answers.estimateSupplies && answers.advancedGarbageInputs,
      result,
    );
    this.validateMaterialField(
      answers.electronicsKg,
      42,
      !answers.estimateSupplies && answers.advancedGarbageInputs,
      result,
    );
  }

  private validateMaterialField(
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    const categoryName = 'co2calculator.questionnaire.categories.material';

    this.validateField(categoryName, 5, value, id, required, result);
  }

  private validateField(
    categoryName: string,
    categoryOder: number,
    value: unknown,
    id: number,
    required: boolean,
    result: Set<UnansweredQuestionModel>,
  ): void {
    if (!this.isValid(value, required)) {
      result.add(new UnansweredQuestionModel(id.toString(), categoryName, categoryOder));
    }
  }

  private isValid(value: unknown, isRequired: boolean): boolean {
    if (!isRequired) {
      return true;
    }

    return this.isAnswered(value);
  }

  private isAnswered(value: unknown): boolean {
    return value !== undefined && value !== null;
  }

  private validatePercentageField(
    percentage: number,
    id: number,
    categoryName: string,
    categoryOder: number,
    result: Set<UnansweredQuestionModel>,
  ): void {
    if (!(percentage !== null && percentage >= 0 && percentage <= 100)) {
      result.add(new UnansweredQuestionModel(id.toString(), categoryName, categoryOder));
    }
  }
}
