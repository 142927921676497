import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageSliderToggleService } from './language-slider-toggle.service';

export interface LanguageItem {
  locale: 'nl' | 'gb';
  alias: string;
}

@Component({
  selector: 'app-language-slider-toggle',
  templateUrl: './language-slider-toggle.component.html',
  styleUrls: ['./language-slider-toggle.component.scss'],
})
export class LanguageSliderToggleComponent implements OnInit {
  options: LanguageItem[] = [
    { alias: 'NL', locale: 'nl' },
    { alias: 'EN', locale: 'gb' },
  ];

  @Input()
  defaultLanguage = 'nl';

  sliderPosition = 0;

  @Output() clickSwipe = new EventEmitter<{ language: 'nl' | 'gb' }>();

  constructor(private languageSliderToggleService: LanguageSliderToggleService) {}

  ngOnInit(): void {
    const newSliderPosition = Math.max(
      0,
      this.options.findIndex((x) => x.locale === this.defaultLanguage),
    );

    this.updateSliderPosition(newSliderPosition);
  }

  handleOptionClick(positionIndex: number): void {
    this.updateSliderPosition(positionIndex);
  }

  handlePan(event: HammerInput): void {
    const { deltaX } = event;

    let newPosition = Math.round(this.sliderPosition + deltaX * 0.01);

    newPosition = Math.max(0, Math.min(newPosition, this.options.length - 1));

    this.updateSliderPosition(newPosition);
  }

  handleSliderClick(): void {
    this.updateSliderPosition((this.sliderPosition + 1) % this.options.length);
  }

  getSliderStyle(): { left: string; width: string } | Record<string, unknown> {
    const width = 100 / this.options.length;
    const left = (this.sliderPosition / this.options.length) * 100;

    return {
      left: `${left}%`,
      width: `${width}%`,
    };
  }

  private updateSliderPosition(position: number): void {
    this.sliderPosition = position;
    const language: 'nl' | 'gb' = this.options[this.sliderPosition].locale;

    this.languageSliderToggleService.setLanguage(language);
    this.clickSwipe.emit({ language });
  }
}
