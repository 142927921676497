import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FiletypeEnum } from '../preview-popup/filetype.enum';
import { PreviewPopupComponent } from '../preview-popup/preview-popup.component';
import { ScoreboardService } from '../scoreboard.service';
import { saveAs } from 'file-saver';
import { Topic } from '../models/topic.model';
import { OptionItem } from '../shared/slider-toggle/slider-group.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SliderGroupState } from '../shared/slider-toggle/xs/slider-group.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss'],
  animations: [
    trigger('changeAnimation', [
      state('hide', style({ opacity: 0 })),
      state('show', style({ opacity: 1 })),
      transition('hide => show', [animate('0.25s ease-in')]),
      transition('show => hide', [animate('0.25s ease-in')]),
    ]),
  ],
})
export class SharePopupComponent implements OnInit {
  companyName!: string;

  scores: Topic[] = [];

  language!: string;

  loading = false;

  sliderItems!: OptionItem[];

  sliderPositions: (number | null)[] = [0, 0];

  assessmentIdsByPosition: string[] = [];

  animation = false;

  stopAnimation = true;

  constructor(
    private dialog: MatDialog,
    public scoreboardService: ScoreboardService,
    private readonly ngZone: NgZone,
    public dialogRef: MatDialogRef<SharePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { companyName: string; scores: Topic[]; language: string },
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.scores = this.data.scores;
    this.companyName = this.data.companyName;
    this.language = this.data.language;

    this.assessmentIdsByPosition = [];
    this.sliderItems = [];

    if (this.scores) {
      this.scores
        .filter((topic) => topic.selected || topic.indeterminate)
        .reverse()
        .forEach((score) => {
          score.assessments
            .filter((assessment) => assessment.selected)
            .reverse()
            .forEach((assessment) => {
              this.assessmentIdsByPosition.push(assessment.id);
              this.sliderItems.push({ alias: assessment.name, id: assessment.id });
            });
        });
      this.sliderPositions = [this.sliderItems.length - 1, 0];
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  previewReport(): void {
    this.startLoading();

    this.scoreboardService.downloadReport(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0]).then((blob) => {
      this.showPreviewPopup('scoreboard.preview.report', FiletypeEnum.PDF, blob as Blob);
    });
  }

  previewEsgImage(): void {
    this.startLoading();

    this.scoreboardService.downloadEsgImage(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0]).then((blob) => {
      this.showPreviewPopup('scoreboard.preview.esg', FiletypeEnum.IMG, blob as Blob);
    });
  }

  previewSdgImage(): void {
    this.startLoading();

    this.scoreboardService.downloadSdgImage(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0]).then((blob) => {
      this.showPreviewPopup('scoreboard.preview.sdg', FiletypeEnum.IMG, blob as Blob);
    });
  }

  previewStatement(): void {
    this.startLoading();

    this.scoreboardService
      .downloadStatement(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0])
      .then((blob) => {
        this.showPreviewPopup('scoreboard.preview.sustainability', FiletypeEnum.IMG, blob as Blob);
      });
  }

  private showPreviewPopup(fileName: string, fileType: FiletypeEnum, file: Blob): void {
    this.dialog.open(PreviewPopupComponent, {
      width: '90vw',
      autoFocus: false,
      data: { title: fileName, fileType, file },
    });

    this.stopLoading();
  }

  saveReport(): void {
    this.startLoading();

    let reportTitle: string;

    if (this.store.selectSnapshot(SliderGroupState.getLanguage) === 'nl') {
      reportTitle = 'Duurzaamheidsrapport';
    } else {
      reportTitle = 'Sustainability Report';
    }

    this.scoreboardService.downloadReport(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0]).then((blob) => {
      this.save(reportTitle, blob, 'pdf');
    });
  }

  saveEsg(): void {
    this.startLoading();

    let reportTitle: string;

    if (this.store.selectSnapshot(SliderGroupState.getLanguage) === 'nl') {
      reportTitle = 'ESG Resultaten';
    } else {
      reportTitle = 'ESG Results';
    }

    this.scoreboardService.downloadEsgImage(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0]).then((blob) => {
      this.save(reportTitle, blob, 'png');
    });
  }

  saveSdg(): void {
    this.startLoading();

    let reportTitle: string;

    if (this.store.selectSnapshot(SliderGroupState.getLanguage) === 'nl') {
      reportTitle = 'SDG Bijdrage';
    } else {
      reportTitle = 'SDG Contribution';
    }

    this.scoreboardService.downloadSdgImage(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0]).then((blob) => {
      this.save(reportTitle, blob, 'png');
    });
  }

  saveStatement(): void {
    this.startLoading();

    let reportTitle: string;

    if (this.store.selectSnapshot(SliderGroupState.getLanguage) === 'nl') {
      reportTitle = 'Duurzaamheidsverklaring';
    } else {
      reportTitle = 'Sustainability Statement';
    }

    this.scoreboardService
      .downloadStatement(this.assessmentIdsByPosition[this.sliderPositions[0] ?? 0])
      .then((blob) => {
        this.save(reportTitle, blob, 'png');
      });
  }

  private save(reportTitle: string, blob: Blob | undefined, format: string): void {
    if (blob) {
      saveAs(blob, `${reportTitle} - ${this.companyName}.${format}`);
    }

    this.stopLoading();
  }

  private startLoading(): void {
    this.loading = true;
    this.ngZone.run(() => this.scoreboardService.startLoading());
  }

  private stopLoading(): void {
    this.loading = false;
    this.ngZone.run(() => this.scoreboardService.stopLoading());
  }

  toggleResults(event: { sliderId: string; sliderPositions: (number | null)[] }): void {
    this.refreshAnimation();
    this.sliderPositions = event.sliderPositions;
  }

  refreshAnimation(): void {
    this.animation = true;

    setTimeout(() => {
      this.animation = false;
    }, 250);
  }
}
