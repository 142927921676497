import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetSliderPositionsAction } from './set-slider-positions-action';
import { LogoutSuccessAction } from '../../../../../auth/xs/actions/logout-success.action';
import { LoginAsCompanySuccessAction } from '../../../../../enterprise/xs/actions/login-as-company-success.action';
import { LogoutFromSmeSuccessAction } from '../../../../../enterprise/xs/actions/logout-from-sme-success.actions';
import { ScoreboardStateModel } from '../../../xs';
import { SetReportLanguageAction } from './set-report-language-action';

interface SliderGroupStateModel {
  positionIndex?: number | undefined;
  optionsLength?: number | undefined;
  origin?: number;
  reportLanguage: 'nl' | 'gb';
}

@State<SliderGroupStateModel>({ name: 'sliderGroup' })
@Injectable()
export class SliderGroupState {
  @Selector()
  static getPosition(state: SliderGroupStateModel): (number | undefined)[] {
    return [state.positionIndex, state.optionsLength, state.origin];
  }

  @Selector()
  static getLanguage(state: SliderGroupStateModel): 'nl' | 'gb' {
    return state.reportLanguage;
  }

  @Action(SetSliderPositionsAction)
  async setSliderPositions(ctx: StateContext<SliderGroupStateModel>, action: SetSliderPositionsAction): Promise<void> {
    ctx.patchState({
      positionIndex: action.payload.positionIndex,
      optionsLength: action.payload.optionsLength,
      origin: action.payload.origin,
    });
  }

  @Action(SetReportLanguageAction)
  async setLanguage(ctx: StateContext<SliderGroupStateModel>, action: SetReportLanguageAction): Promise<void> {
    ctx.patchState({ reportLanguage: action.language });
  }

  @Action([LogoutSuccessAction, LoginAsCompanySuccessAction, LogoutFromSmeSuccessAction])
  clearPositions(ctx: StateContext<ScoreboardStateModel>): void {
    ctx.setState({});
  }
}
