import { Injectable } from '@angular/core';
import { AbstractService } from 'src/app/shared/services/abstract.service';
import { SetReportLanguageAction } from '../xs/set-report-language-action';

@Injectable({ providedIn: 'root' })
export class LanguageSliderToggleService extends AbstractService {
  setLanguage(language: 'nl' | 'gb'): void {
    this.store.dispatch(new SetReportLanguageAction(language));
  }
}
